<template>
  <div id="questions">
    <h1>{{ $t("QUESTION.QUESTIONS") }}</h1>
    <b-table
      v-if="questions.length > 0"
      striped
      hover
      :fields="$t('QUESTION.FIELDS')"
      :items="questions"
    >
      <template #cell(actions)="row">
        <!-- <b-button
          size="sm"
          @click="goToEditPage(row.item.id)"
          class="mr-2 btn-primary"
        >
          {{ $t("COMMON.EDIT") }}
        </b-button> -->
        <b-button
          size="sm"
          @click="deleteQuestion(row.item.id)"
          class="mr-2 btn-danger"
        >
          {{ $t("COMMON.DELETE") }}
        </b-button>
      </template>
      <template #cell(show_details)="row">
        <b-button
          :disabled="row.item.type !== 'MCQ'"
          size="sm"
          @click="row.toggleDetails"
          class="mr-2"
        >
          {{ row.detailsShowing ? "Hide" : "Show" }} Details
        </b-button>

        <!-- As `row.showDetails` is one-way, we call the toggleDetails function on @change -->
        <b-form-checkbox
          style="opacity: 0"
          v-model="row.detailsShowing"
          @change="row.toggleDetails"
        >
          Details via check
        </b-form-checkbox>
      </template>

      <template #row-details="row">
        <b-card>
          <ul class="pl-3">
            <li
              v-for="option in row.item.options"
              :key="'q' + row.item.id + 'an' + option.id"
            >
              {{ option.text }}
            </li>
          </ul>
        </b-card>
      </template>
    </b-table>
    <b-alert v-else class="mt-5" show>Currently There's no questions</b-alert>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  created() {
    this.getQuestions();
  },
  methods: {
    ...mapActions({
      getQuestions: "getQuestions",
      deleteQ: "deleteQuestion",
    }),
    goToEditPage(id) {},
    deleteQuestion(id) {
      if (confirm("Are you sure you want to delete this question?")) {
        this.deleteQ({
          vm: this,
          id,
        });
      } else {
        return;
      }
    },
  },
  computed: {
    ...mapGetters({
      questions: "questions",
    }),
  },
};
</script>

<style lang="scss" scoped>
#questions {
  background: #fff;
  padding: 20px;

  h1 {
    font-size: 20px;
    color: #3c4eba;
    font-weight: 500;
  }
}
</style>
